<template>
    <div>
       
    </div>
</template>
<script>
export default {
    props: ['headers', 'items', 'tableKey', 'loading', 'height'],
    data: ()=>({
        columnDrag: false,
    })
}
</script>
<style scoped>

.ghost-card {
    opacity: 0.8;
    background: var(--v-modal-base) !important;
    border: 1px solid var(--v-primary-base);
    /* padding: 10px; */
  }</style>